// 引入axios
import axios from 'axios';
import router from '@/router'
// 引入vuex
import store from '../store'
import { Message } from 'element-ui'

let baseURL;
if (process.env.NODE_ENV === 'production') {
    baseURL = '/dk'
} else {
    baseURL = '/app-api'
}
const api = axios.create({
    baseURL,
    timeout: 15000 // 请求超时时间 当请求时间超过15秒还未取得结果时 提示用户请求超时
})
// interceptors axios的拦截器对象
api.interceptors.request.use(
    config => {
        // config 请求的信息-
        // console.log('config', config)
        // if (store.state.token != '') {
        //     config.headers.token = store.state.token
        // }

        config.headers['Content-Type'] = 'application/json'

        // console.log('config', config.headers)
        return config // 将配置完成的config对象返回出去 如果不返回 请求则不会进行
    },
    err => {
        // 请求发生错误时的处理 抛出错误
        // console.log('err', err);
        return Promise.reject(err)
    }
)

api.interceptors.response.use(
    response => {
        // 我们一般在这里处理，请求成功后的错误状态码 例如状态码是500，404，403
        // res 是所有相应的信息
        if (response.status == 200) {
            if (response.data.code === '9997') {
                Message.error('登陆失效或权限不足～')
                if (process.env.NODE_ENV === 'production') {
                    store.commit('SetIsLogin', false) // 登陆状态设为 false
                    localStorage.setItem('isLogin', false)
                    router.replace({ name: 'login' })
                    localStorage.removeItem('userInfo')
                }
                if (store.state.isLoading) {
                    store.commit('SetIsLoading', false)
                }
            } else {
                return response.data
            }
        } else {
            store.commit('SetIsLoading', false)
            return response
        }
    },
    err => {
        // 服务器响应发生错误时的处理
        // console.log('失败返回---', err)
        store.commit('SetIsLoading', false)
        return Promise.reject(err)
    }
)


export function Post(url, params) {
    console.log('接口:', url, '---参数:', params);
    return new Promise((resolve, reject) => {
        api.post(url, params).then(
            response => {
                console.log('成功返回---', response)
                resolve(response)
            }
        ).catch(error => {
            Message.error('网络出现出错，请稍后重试~',error)
            reject(error)
        })
    })
}
export {
    baseURL
}