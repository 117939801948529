import router from './router'
import store from './store'
import { Message } from 'element-ui'

const whiteList = ['/login']

// 登陆持久化
let isLogin = localStorage.getItem('isLogin')
if (isLogin) {
  store.commit('SetIsLogin', isLogin)
  store.commit('SetUserInfo', JSON.parse(localStorage.getItem('userInfo')))
}
/*
   登陆 》 保存本地 》 跳转页面
   中途刷新 调用接口没有返回过期 通过持久化 保持页面
   中途刷新 调用接口返回过期 设置vuex 过期 然后跳转至登陆页面重新登陆
 */

// 全局前置导航钩子 
router.beforeEach((to, from, next) => {
  // console.log('全局前置导航钩子', to, from);
  document.title = '云斑马智能订单运管平台';
  if (store.state.isLogin) {
    // vuex中每次保存当前路由位置
    store.commit('SetDefaultPath', to.path)
    // vuex中每次保存当前面包屑位置
    let arr = to.matched.map(el => {
      return {title: el.meta.title,path: el.path,}
    })
    store.commit('SetBreadcrumbArr', arr)

    if (Object.keys(from.query).length === 0) {//判断路由来源是否有query，处理不是目的跳转的情况
      next()
    } else {
      let redirect = from.query.redirect//如果来源路由有query
      if (to.path === redirect) {//这行是解决next无限循环的问题
        next()
      } else {
        next({ path: redirect })//跳转到目的路由
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
    }
  }

})