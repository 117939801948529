<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    
    return {}
  },
  mounted() {
  },
  methods: {

  },
}
</script>

<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.shadow{
  box-shadow: 0 0 10px rgba(209,211,212,0.6);
}

.pagination {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
