import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: true,
    isLoading: false,
    userInfo: {
      name: '',
      phone: ''
    },
    defaultPath: '/home',
    breadcrumbArr: [{ title: '首页', path: '/home' }],
    pagesRouters: [
      {
        title: '首页',
        path: '/home',
        icon: 'el-icon-school',
        childrens: []
      },
      {
        title: '订单中心',
        path: '/order',
        icon: 'el-icon-s-order',
        childrens: [
          {
            title: '订单管理',
            path: '/orderManage',
            childrens: []
          },
          {
            title: '物流监控',
            path: '/orderMonitoring',
            childrens: []
          },
        ]
      },
      {
        title: '商品中心',
        path: '/commodity',
        icon: 'el-icon-goods',
        childrens: [
          {
            title: '我的渠道',
            path: '/myChannel',
            childrens: []
          },
          {
            title: '我的仓库',
            path: '/myWarehouse',
            childrens: []
          },
          {
            title: '我的商品',
            path: '/myCommodity',
            childrens: []
          },
          // {
          //   title: '发货配置',
          //   path: '/dispatchConfig',
          //   childrens: []
          // },
          {
            title: '商品库存',
            path: '/commodityCenter',
            childrens: []
          }
        ],
      },
      {
        title: '配置中心',
        path: '/info',
        icon: 'el-icon-user',
        childrens: [
          {
            title: '基本信息',
            path: '/baseInfo',
            childrens: []
          },
          {
            title: '人员配置',
            path: '/userConfig',
            childrens: []
          },
        ]
      },
    ]
  },
  mutations: {
    SetIsLogin(state, newVal) {
      state.isLogin = newVal
    },
    SetIsLoading(state, newVal) {
      state.isLoading = newVal
    },
    SetUserInfo(state, newVal){
      state.userInfo = newVal
    },
    SetDefaultPath(state, newVal) {
      state.defaultPath = newVal
    },
    SetBreadcrumbArr(state, newVal) {
      state.breadcrumbArr = newVal
    },
    SetPagesRouters(state, newVal) {
      state.pagesRouters = newVal
    },
  },
  getters: {
  },
  actions: {
  },
  modules: {
  }
})
