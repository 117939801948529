<template>
    <div class="login">
        <div class="body">
            <div v-if="pageType == 1" class="login_box shadow">
                <el-tabs v-model="loginType" @tab-click="handleLoginType">
                    <!-- <el-tab-pane label="微信扫码登陆" name="1">
                        <div class="scan_code">
                            <img src="@/assets/image/ewm.jpeg" alt="微信扫码登陆">
                            <p>请使用***APP扫码二维码</p>
                            <ul>
                                <li @click="pageType = 2">立即注册</li>
                                <li>忘记账号</li>
                                <li>忘记密码</li>
                            </ul>
                        </div>
                    </el-tab-pane> -->
                    <el-tab-pane label="账号密码登陆" name="1">
                        <div class="normal_login">
                            <el-form class="form" ref="loginForm" :rules="rules" :model="loginForm" label-position="left"
                                label-width="100px">
                                <el-form-item label="用户名" prop="userName">
                                    <el-input v-model="loginForm.userName" placeholder="请输入用户名"></el-input>
                                </el-form-item>
                                <el-form-item label="密码" prop="passwrod">
                                    <el-input v-model="loginForm.passwrod" type="password" placeholder="请输入密码"></el-input>
                                </el-form-item>
                                <el-form-item label="验证码" prop="verifyImgCode">
                                    <el-row type="flex" justify="space-between">
                                        <el-col :span="14" style="margin-bottom: 0px;line-height: auto;">
                                            <el-input v-model="loginForm.verifyImgCode" placeholder="请输入图片验证码"></el-input>
                                        </el-col>
                                        <el-col :span="8" style="margin-bottom: 0px;">
                                            <img :src="codeImg" :key="codeImg" @click="getImgCode"
                                                style="height: 40px;width: 100%;display: block;">
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                            </el-form>
                            <div class="login_btn" @click="userLogin('PASS')">登陆</div>
                            <!-- <div class="register_btn" @click="pageType = 2">立即注册</div> -->
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="手机短信登陆" name="2">
                        <div class="normal_login">
                            <el-form class="form" ref="phoneForm" :model="phoneForm" :rules="rules" label-position="left"
                                label-width="120px">
                                <el-form-item label="手机号：" prop="userPhone">
                                    <el-input v-model="phoneForm.userPhone" placeholder="请输入手机号"></el-input>
                                </el-form-item>
                                <el-form-item label="图形验证码" prop="verifyImgCode">
                                    <el-row type="flex" justify="space-between">
                                        <el-col :span="14" style="margin-bottom: 0px;">
                                            <el-input v-model="phoneForm.verifyImgCode" placeholder="请输入图片验证码"></el-input>
                                        </el-col>
                                        <el-col :span="8" style="margin-bottom: 0px;">
                                            <img :src="codeImg" :key="codeImg" @click="getImgCode"
                                                style="height: 100%;width: 100%;display: block;">
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                                <el-form-item label="验证码：" prop="verifyCode">
                                    <el-row type="flex" justify="space-between">
                                        <el-col :span="14">
                                            <el-input v-model="phoneForm.verifyCode" placeholder="请输入短信验证码"></el-input>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-button v-if="second === 60" v-debounce="sendVerifyCode" type="primary"
                                                style="width: 100%; padding-left: 0px;padding-right: 0px;">
                                                获取验证码
                                            </el-button>
                                            <el-button v-else type="primary"
                                                style="width: 100%; padding-left: 0px;padding-right: 0px;">
                                                {{ second }}秒
                                            </el-button>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                            </el-form>
                            <div class="login_btn" @click="userLogin('SMS')">登陆</div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div v-if="pageType == 2" class="register_box shadow">
                <h4
                    style="font-size: 26px;color: #1d2128;text-align: center;display: block;line-height: 30px; padding: 0px 0px 15px;font-weight: bold;">
                    账号注册</h4>
                <el-form class="form" ref="registerForm" :model="registerForm" label-position="left" label-width="140px">
                    <el-form-item label="登陆邮箱：">
                        <el-input v-model="registerForm.userName" placeholder="请输入登陆邮箱"></el-input>
                    </el-form-item>
                    <el-form-item label="登陆密码：">
                        <el-input v-model="registerForm.passwrod" placeholder="请输入登陆密码"></el-input>
                    </el-form-item>
                    <el-form-item label="确认登陆密码：">
                        <el-input v-model="registerForm.userName" placeholder="请再次确认登陆密码"></el-input>
                    </el-form-item>
                    <el-form-item label="绑定手机号：">
                        <el-input v-model="registerForm.userName" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="手机验证码：">
                        <el-row type="flex" justify="space-between">
                            <el-col :span="14">
                                <el-input v-model="registerForm.verifyCode" placeholder="请输入短信验证码"></el-input>
                            </el-col>
                            <el-col :span="8">
                                <el-button type="primary"
                                    style="width: 100%; padding-left: 0px;padding-right: 0px;">获取验证码</el-button>
                            </el-col>
                        </el-row>
                    </el-form-item>
                </el-form>
                <div class="login_btn" @click="pageType = 2">立即注册</div>
            </div>
        </div>
        <div class="footer">
            <!-- <p>
                <span @click="to('https://www.baidu.com/')">关于我们</span>｜
                <span @click="to('https://www.baidu.com/')">联系我们</span>
            </p> -->
            <p>江苏优选云仓供应链有限公司  苏ICP备2020053305号-5</p>
        </div>
    </div>
</template>

<script>
import { baseURL } from "@/api/axios";
import { mapMutations } from 'vuex';
let login = {
    data() {
        return {
            codeImg: baseURL + '/code.jsp',
            loginForm: {
                userName: '', // fxs
                passwrod: '', // Fxs@1213
                userPhone: '',
                verifyCode: '',
            },
            phoneForm: {
                userPhone: '',
                verifyImgCode: '',
                verifyCode: '',
            },
            timer: null,
            second: 60,
            rules: {
                userName: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { min: 3, message: '最少输入三位字符', trigger: 'blur' }
                ],
                passwrod: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, message: '最少输入6位字符', trigger: 'blur' }
                ],
                userPhone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    {
                        required: true,
                        pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
                        message: '请输入正确的手机号码',
                        trigger: 'blur'
                    }
                ],
                verifyImgCode: [
                    { required: true, message: '请输入图形验证码', trigger: 'blur' },
                    { max: 4, message: '请输入四位数图形验证码', trigger: 'blur' }
                ],
                verifyCode: [
                    { required: true, message: '请输入短信验证码', trigger: 'blur' },
                    { max: 6, message: '请输入六位数短信验证码', trigger: 'blur' }
                ],
            }
        }
    },
    created() {

    },
    methods: {
        ...mapMutations(['SetIsLogin','SetUserInfo']),
        sendVerifyCode() {
            let msgArr = []
            this.$refs.phoneForm.validateField(['userPhone', 'verifyImgCode'], (msg) => {
                if (msg) {
                    msgArr.push(msg)
                }
            })
            console.log(msgArr);
            if (msgArr.length === 0) {
                let data = {
                    USERNAME: this.phoneForm.userPhone,
                    USERPASS: '',
                    CODE: this.phoneForm.verifyImgCode,
                    METHOD: 'SEND'
                }
                this.$post('/login_action.jsp', data).then(res => {
                    if (res.code === '0000') {
                        this.$message.success('发送成功～')
                        this.timer = setInterval(() => {
                            this.second--
                            if (this.second === 0) {
                                this.second = 60
                                clearInterval(this.timer)
                            }
                        }, 1000)
                    } else {
                        this.getImgCode()
                        this.$message.error(res.msg)
                    }
                })
            }
        },
        userLogin(type) {
            let formName = type === 'PASS' ? 'loginForm' : 'phoneForm'
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.$store.commit('SetIsLoading', true)
                    let data
                    if (type === 'PASS') {
                        data = {
                            USERNAME: this.loginForm.userName,
                            USERPASS: this.$md5(this.loginForm.passwrod),
                            CODE: this.loginForm.verifyImgCode,
                            METHOD: type
                        }
                    } else if (type === 'SMS') {
                        data = {
                            USERNAME: this.phoneForm.userPhone,
                            USERPASS: this.phoneForm.verifyCode,
                            CODE: this.phoneForm.verifyImgCode,
                            METHOD: type
                        }
                    }

                    let res = await this.$post('/login_action.jsp', data)
                    this.$store.commit('SetIsLoading', false)
                    if (res.code === '0000') {
                        let userInfo = {
                            name:res.name,
                            phone: res.phone,
                            BM_FXS_ZHTP: res.BM_FXS_ZHTP,
                            BM_ZH_TYPE: res.BM_ZH_TYPE
                        }
                        this.SetUserInfo(userInfo)
                        localStorage.setItem('userInfo',JSON.stringify(userInfo))
                        
                        this.SetIsLogin(true)
                        localStorage.setItem('isLogin', true)
                        this.$router.push({ name: 'home' })
                    } else {
                        this.$message.error(res.msg)
                        this.getImgCode()
                    }
                } else {
                    this.getImgCode()
                    return false;
                }
            });
        },
        getImgCode() {
            this.phoneForm.verifyImgCode = ''
            this.loginForm.verifyImgCode = ''
            this.codeImg = baseURL + '/code.jsp?t=' + new Date().getTime()
            // console.log( this.codeImg);
        },
    }
}
let register = {
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === "" || value.length <= 6) {
                callback(new Error("请输入新密码(6位以上)"));
            } else {
                if (this.form.confirmPassword !== "") {
                    this.$refs.ruleForm.validateField("confirmPassword");
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.form.newPassword) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            registerForm: {
                userName: '',
                passwrod: '',
                verifyCode: '',
            },
        }
    }
}
export default {
    name: 'login',
    mixins: [login, register],
    data() {
        return {
            pageType: 1, //1: 登陆 2: 注册
            loginType: '1', // 1:账号密码  2: 手机号验证码
        }
    },

    mounted() {

    },
    methods: {

        handleLoginType(tab, event) {
            console.log(tab, event);
            this.getImgCode()
        },
        to(url) {
            window.open(url, '_blank')
        },
    },
}
</script>

<style lang="scss" scoped>
.login {
    width: 100vw;
    height: 100vh;
    min-width: 1280px;
    background: url('@/assets/image/login_bg.jpeg') no-repeat center center;
    background-size: 100% 100%;
    position: relative;
    background-color: antiquewhite;
}

::v-deep .el-tabs__nav-wrap::after {
    background-color: #ffffff;
}

::v-deep .el-tabs__item {
    font-size: 18px;
}

::v-deep .el-form-item__label {
    font-size: 16px;
}

.body {
    width: 100%;
    height: 100%;
    position: relative;

    .login_box,
    .register_box {
        width: 490px;
        min-height: 420px;
        background-color: #ffffff;
        border-radius: 10px;
        position: absolute;
        right: 15%;
        top: 50%;
        transform: translateY(-50%);
        box-sizing: border-box;
        padding: 15px;
    }

    .login_btn {
        cursor: pointer;
        width: 92%;
        height: 55px;
        border-radius: 5px;
        margin: 30px auto 15px;
        font-size: 20px;
        color: #FFFFFF;
        text-align: center;
        line-height: 55px;
        box-shadow: 0 7px 20px rgb(252 177 58 / 35%);
        background: -webkit-linear-gradient(left, #f7a542, #ecc459);
        background: -o-linear-gradient(right, #f7a542, #ecc459);
        background: -moz-linear-gradient(right, #f7a542, #ecc459);
        background: linear-gradient(to right, #f7a542, #ecc459);
    }

    .register_btn {
        cursor: pointer;
        width: 92%;
        margin: 10px auto 0px;
        font-size: 20px;
        color: #fca82c;
        text-align: center;
        line-height: 30px;
    }
}

.scan_code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 227px;
        height: 227px;
        margin: 20px auto 0px;
        display: block;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
    }

    p {
        font-size: 18px;
        color: #fca82c;
        text-align: center;
        display: block;
        line-height: 20px;
        padding: 20px 0;
    }

    ul {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        li {
            cursor: pointer;
            width: calc(100% / 3);
            text-align: center;
            font-size: 16px;
            border-right: 1px solid #d8d8d8;
        }

        li:last-child {
            border: none;
        }
    }
}

.normal_login {
    width: 100%;

    .form {
        width: 80%;
        margin: 10px auto 0px;
    }
}

.register_box {
    width: 100%;

    .form {
        width: 92%;
        margin: 0px auto;
    }
}

.footer {
    width: 100%;
    text-align: center;
    letter-spacing: 2px;
    line-height: 16px;
    font-size: 12px;
    color: #333333;
    text-align: center;
    position: fixed;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);

    p>span {
        letter-spacing: 2px;
        line-height: 16px;
        font-size: 12px;
        color: #333333;
        cursor: pointer;
    }

    p+p {
        margin-top: 5px;
    }
}
</style>