<template>
    <div class="index">
        <el-container style="height: 100%;">
            <el-aside width="200px">
                <my-aside></my-aside>
            </el-aside>
            <el-container>
                <el-header>
                    <my-header></my-header>
                </el-header>
                <el-main v-loading="$store.state.isLoading" element-loading-text="拼命加载中..."
                    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                    <div class="content">
                        <div class="readcrumb">
                            <span>位置：</span>
                            <el-breadcrumb separator-class="el-icon-arrow-right">
                                <template v-for="(item, index) in $store.state.breadcrumbArr">
                                    <el-breadcrumb-item :key="index" v-if="item.path">{{ item.title }}</el-breadcrumb-item>
                                </template>
                            </el-breadcrumb>
                        </div>
                        <router-view />
                    </div>
                </el-main>
                <el-footer>
                    <my-footer></my-footer>
                </el-footer>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import myAside from '@/components/aside.vue';
import myFooter from '@/components/footer.vue';
import myHeader from "@/components/header.vue";
export default {
    name: 'index',
    components: {
        myAside,
        myFooter,
        myHeader,
    },
    data() {
        return {}
    },
    created() {
    },
    mounted() { },
    methods: {},
}
</script>

<style lang="scss" scoped>
.index {
    width: 100%;
    height: 100%;
}

.el-aside {
    position: relative;
    z-index: 100;
    box-shadow: 0px 0px 12px #cbcccc;
}

.el-header {
    background-color: #ffffff;
    color: #333;
    padding: 0px 20px !important;
    min-height: 65px;
    height: auto !important;
}

.el-main {
    background-color: #f7f7f7;
    color: #333;
    padding: 0px !important;
    position: relative;
}

.el-footer {
    background-color: #ffffff;
    padding: 0px !important;
    height: auto !important;
}

.content {
    padding: 10px 20px 20px 20px;

    .readcrumb {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        color: #606266;
        margin-bottom: 10px;

        span {
            letter-spacing: 2px;
        }
    }
}

::v-deep .el-divider--horizontal {
    margin-top: 0px !important;
}
</style>
