import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

import index from '../views/index.vue'
import login from '../views/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    meta: { title: "云斑马智能订单运管平台" },
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: resolve => require(['@/views/home/home'], resolve),
        meta: { title: "首页" },
      }
    ]
  },
  {
    path: '/order',
    name: 'order',
    component: index,
    meta: { title: "订单中心" },
    children: [
      {
        path: '/order/orderManage',
        name: 'orderManage',
        component: resolve => require(['@/views/order/orderManage'], resolve),
        meta: { title: "订单管理" },
      },
      {
        path: '/order/orderMonitoring',
        name: 'orderMonitoring',
        component: resolve => require(['@/views/order/orderMonitoring'], resolve),
        meta: { title: "物流监控" },
      },
    ]
  },
  {
    path: '/commodity',
    name: 'commodity',
    component: index,
    meta: { title: "商品中心" },
    children: [
      {
        path: '/commodity/myChannel',
        name: 'myChannel',
        component: resolve => require(['@/views/commodity/myChannel'], resolve),
        meta: { title: "我的渠道" },
      },
      {
        path: '/commodity/myWarehouse',
        name: 'myWarehouse',
        component: resolve => require(['@/views/commodity/myWarehouse'], resolve),
        meta: { title: "我的仓库", breadcrumbArr: ['商品中心', '我的仓库'] },
      },
      {
        path: '/commodity/myWarehouse/warehouseDetails',
        name: 'warehouseDetails',
        component: resolve => require(['@/views/commodity/warehouseDetails'], resolve),
        meta: { title: "仓库详情" },
        beforeEnter: (to, from, next) => {
          store.commit('SetDefaultPath', '/commodity/myWarehouse')
          store.commit('SetBreadcrumbArr', [
            { "title": "商品中心", "path": "/commodity" },
            { "title": "我的仓库", "path": "/commodity/myWarehouse" },
            { "title": "仓库详情", "path": "/commodity/myWarehouse/warehouseDetails" }
          ])
          next()
        }
      },
      {
        path: '/commodity/myWarehouse/warehouseDetails/warehouseEntry',
        name: 'warehouseEntry',
        component: resolve => require(['@/views/commodity/warehouseEntry'], resolve),
        meta: { title: "入库单" },
        beforeEnter: (to, from, next) => {
          store.commit('SetDefaultPath', '/commodity/myWarehouse')
          console.log(store.state.breadcrumbArr);
          store.commit('SetBreadcrumbArr', [
            { "title": "商品中心", "path": "/commodity" },
            { "title": "我的仓库", "path": "/commodity/myWarehouse" },
            { "title": "仓库详情", "path": "/commodity/myWarehouse/warehouseDetails" },
            { "title": "入库单", "path": "/commodity/myWarehouse/warehouseDetails/warehouseEntry" }
          ])
          next()
        }
      },
      {
        path: '/commodity/myCommodity',
        name: 'myCommodity',
        component: resolve => require(['@/views/commodity/myCommodity'], resolve),
        meta: { title: "我的商品" },
      },
      {
        path: '/commodity/dispatchConfig',
        name: 'dispatchConfig',
        component: resolve => require(['@/views/commodity/dispatchConfig'], resolve),
        meta: { title: "发货配置" },
      },
      {
        path: '/commodity/commodityCenter',
        name: 'commodityCenter',
        component: resolve => require(['@/views/commodity/commodityCenter'], resolve),
        meta: { title: "商品库存" },
      },
      {
        path: '/commodity/commodityCenter/commodityDetail',
        name: 'commodityDetail',
        component: resolve => require(['@/views/commodity/commodityDetail'], resolve),
        meta: { title: "库存信息" },
        beforeEnter: (to, from, next) => {
          store.commit('SetDefaultPath', '/commodity/commodityCenter')
          console.log(store.state.breadcrumbArr);
          store.commit('SetBreadcrumbArr', [
            { "title": "商品中心", "path": "/commodity" },
            { "title": "商品库存", "path": "/commodity/commodityCenter" },
            { "title": "库存信息", "path": "/commodity/commodityCenter/commodityDetail" },
          ])
          next()
        }
      },
      {
        path: '/commodity/commodityCenter/commodityDetail/commodityCenterDetail',
        name: 'commodityCenterDetail',
        component: resolve => require(['@/views/commodity/commodityCenterDetail'], resolve),
        meta: { title: "库存明细" },
        beforeEnter: (to, from, next) => {
          store.commit('SetDefaultPath', '/commodity/commodityCenter')
          console.log(store.state.breadcrumbArr);
          store.commit('SetBreadcrumbArr', [
            { "title": "商品中心", "path": "/commodity" },
            { "title": "商品库存", "path": "/commodity/commodityCenter" },
            { "title": "库存信息", "path": "/commodity/commodityCenter/commodityDetail" },
            { "title": "库存明细", "path": "/commodity/commodityCenter/commodityDetail/commodityCenterDetail" }
          ])
          next()
        }
      },
    ]
  },
  {
    path: '/info',
    name: 'info',
    component: index,
    meta: { title: "配置中心" },
    children: [
      {
        path: '/info/baseInfo',
        name: 'baseInfo',
        component: resolve => require(['@/views/info/baseInfo'], resolve),
        meta: { title: "基本信息" },
      },
      {
        path: '/info/userConfig',
        name: 'userConfig',
        component: resolve => require(['@/views/info/userConfig'], resolve),
        meta: { title: "人员配置" },
      }
    ]
  },

  {
    path: '/login',
    name: 'login',
    component: login,
    meta: { title: "云斑马-为智能发货而努力" },
  }
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
export default router
