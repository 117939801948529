<template>
    <div class="footer shadow">
        <div class="info">
            <!-- <p>
                <span @click="to('https://www.baidu.com/')">关于大鱼云仓</span>|
                <span @click="to('https://www.baidu.com/')">法律声明及隐私政策</span>|
                <span @click="to('https://www.baidu.com/')">联系我们</span>
            </p> -->
            <p>江苏跨界网络发展有限公司 苏ICP备11032112号</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'myFooter',
    data() {
        return {}
    },
    mounted() {},
    methods: {
        to(url) {
            window.open(url, '_blank')
        },
    },
}
</script>

<style lang="scss" scoped>
.footer {
    width: 100%;
    position: relative;

    .info {
        width: 100%;
        background-color: #e0e3e5;
        text-align: center;
        letter-spacing: 2px;
        line-height: 16px;
        font-size: 12px;
        color: #8e8e8e;
        padding: 5px 0px;

        p>span {
            letter-spacing: 2px;
            line-height: 16px;
            font-size: 12px;
            color: #8e8e8e;
            cursor: pointer;
        }
        p+p {
            margin-top: 5px;
        }
    }
}
</style>