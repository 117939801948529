<template>
    <el-row class="header">
        <el-col class="right" :span="24">
            <!-- <div class="msg">
                <el-badge :value="12">
                    <i class="el-icon-chat-dot-round"></i>
                </el-badge>
                <span>消息中心</span>
            </div> -->
            <div class="user_role">
                <!-- <span>运营经理：</span> -->
                <span>{{ $store.state.userInfo.name }}</span><span style="margin-left: 15px;">{{ phone }}</span>
            </div>
            <!-- <div class="avatar">
                <el-avatar :size="55" src="">
                    <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" />
                </el-avatar>
            </div> -->
            <span style="font-weight: 700;margin: 0px 5px;margin-right: 12px;">｜</span>
            <el-dropdown trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">
                    配置中心<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="(item, idx) in dropdownList" :key="idx" :command="item"
                        :disabled="activeDropdown == item.path">{{ item.title }}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </el-col>
    </el-row>
</template>
<script>
export default {
    name: 'myHeader',
    data() {
        return {
            dropdownList: [
                {
                    title: '基本信息',
                    path: '/baseInfo',
                },
                {
                    title: '人员配置',
                    path: '/userConfig',
                },
                {
                    title: '退出',
                    path: '/login',
                },
            ],
        }
    },
    created() {
        // 控制头部下拉菜单
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        if (userInfo.BM_ZH_TYPE == '1' && userInfo.BM_FXS_ZHTP == '3') {
            this.dropdownList =  [
                {
                    title: '人员配置',
                    path: '/userConfig',
                },
                {
                    title: '退出',
                    path: '/login',
                },
            ]
        }
    },
    mounted() { },
    computed: {
        activeDropdown() {
            let path = this.$store.state.defaultPath.split('/')
            return "/" + path[path.length - 1]
        },
        phone() {
            let phone = this.$store.state.userInfo.phone
            if (!/^1[3456789]\d{9}$/.test(phone)) {
                return '';
            }
            return phone.substring(0, 3) + '****' + phone.substring(phone.length - 4);
        }
    },
    methods: {
        handleCommand(command) {
            console.log(command);
            if (command.path == '/login') {
                this.$store.commit('SetIsLoading', true)
                this.$post('/login_action.jsp', {
                    USERNAME: '',
                    USERPASS: '',
                    CODE: '',
                    METHOD: 'LOGOFF'
                }).then(res => {
                    this.$store.commit('SetIsLoading', false)
                    if (res.code === '0000') {
                        this.$store.commit('SetIsLogin', false)
                        localStorage.setItem('isLogin', false)
                        localStorage.removeItem('userInfo')
                        this.$router.replace({ name: 'login' })
                    } else {
                        this.$message.error(res.msg)
                    }

                })
            } else {
                this.$store.commit('SetDefaultPath', '/info' + command.path)
                this.$router.push({ path: '/info' + command.path })
            }

        }
    },

}
</script>

<style lang="scss" scoped>
.header {
    width: 100%;
    position: relative;

    .el-col {
        height: 65px;
        margin-bottom: 0px !important;
    }

    .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #666666;
        font-size: 14px;

        .msg {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;

            .el-icon-chat-dot-round {
                font-size: 28px;
            }

            span {
                margin-left: 15px;
            }
        }

        .user_role {
            margin: 0px 10px;
        }

        .avatar {
            margin-left: 10px;
            margin-right: 15px;
        }

    }
}
</style>
