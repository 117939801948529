let mixin = {
    // data() {
    //     return {
            
    //     }
    // },
    methods: {
        resetForm(formName) {
            console.log('清空此表单--', formName);
            this.$refs[formName].resetFields();
        },
        // 分页函数 size
        handleSizeChange(val, methodName,params) {
            this.currentSize = val
            if(params){
                this[methodName](params)
            }else{
                this[methodName]()
            }
           
        },
        // 分页函数 page
        handleCurrentChange(val, methodName,params) {
            this.currentPage = val
            if(params){
                this[methodName](params)
            }else{
                this[methodName]()
            }
        },
        

    }
}
export default mixin;