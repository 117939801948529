import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './permission'
// 引入ElementUI
import ElementUI from 'element-ui';
// 使用默认ElementUI主题色
// import 'element-ui/lib/theme-chalk/index.css';
// 使用ElementUI自定主体色
import '@/assets/css/element-theme-#FCA82C/index.css';
import globalMixin from './mixins/globalMixin'
Vue.mixin(globalMixin)
// 全局添加 axios封装请求方法
import { Post } from './api/axios'
Vue.prototype.$post = Post

import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

// 简单按钮控制显示 
// 仓库权限
Vue.prototype.$isShowBtn = ()=>{
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  return userInfo.BM_ZH_TYPE == '1' && userInfo.BM_FXS_ZHTP == '3' ? false : true
}
// 
Vue.prototype.$isShowBotton = ()=>{
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  return userInfo.BM_ZH_TYPE == '1' && userInfo.BM_FXS_ZHTP == '0' ? false : true
}

// 关闭生产模式的消息
Vue.config.productionTip = false
// 使用ElementUI
Vue.use(ElementUI);

// 注册一个全局自定义指令 `v-focus`
Vue.directive('focus', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function (el) {
    // 聚焦元素
    el.focus()
  }
})
// 自定义 函数防抖 <button v-debounce="doSomething">点击</button>
Vue.directive('debounce', {
  inserted: function (el, binding) {
    let timer = null;
    el.addEventListener('click', function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        binding.value();
      }, 500);
    });
  }
});
// 自定义 函数节流 <button v-throttle="doSomething">点击</button>
Vue.directive('throttle', {
  inserted: function (el, binding) {
    let timer = null;
    let lastTime = 0;
    el.addEventListener('click', function () {
      const now = Date.now();
      if (now - lastTime > 500) {
        lastTime = now;
        binding.value();
      }
    });
  }
});

// 正式环境清除所有console.log
if (process.env.NODE_ENV === 'production') {
  if (window) {
    window.console.log = () => {};
  }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
