<template>
    <div class="aside">
        <div class="logo">
            <img src="@/assets/image/logo.png" alt="logo">
        </div>
        <el-menu :default-active="$store.state.defaultPath" class="el-menu-vertical-demo" :router="true"
            @select="handleSelect">
            <template v-for="(el, idx) in $store.state.pagesRouters">
                <!-- <el-menu-item v-if="el.childrens.length == 0" :index="el.path" :key="idx"> -->
                <el-menu-item v-if="el.childrens.length == 0" :index="el.path" :route="el.path" :key="idx">
                    <i :class="[el.icon]"></i>
                    <span slot="title">{{ el.title }}</span>
                </el-menu-item>

                <el-submenu v-else :index="idx.toString()" :key="el.path">
                    <template slot="title">
                        <i :class="[el.icon]"></i>
                        <span>{{ el.title }}</span>
                    </template>
                    <el-menu-item v-for="(c, i) in el.childrens" :key="i" :index="el.path + c.path"
                        :route="el.path + c.path">{{ c.title
                        }}</el-menu-item>
                </el-submenu>
            </template>
        </el-menu>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
    name: 'Aside',
    data() {
        return {

        }
    },
    created() {
        // 控制侧边菜单
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        // console.log(userInfo);
        if (userInfo.BM_ZH_TYPE == '1' && userInfo.BM_FXS_ZHTP == '3') {
            // console.log('低权限');
            let pagesRouters = [
                {
                    title: '首页',
                    path: '/home',
                    icon: 'el-icon-school',
                    childrens: []
                },
                {
                    title: '订单中心',
                    path: '/order',
                    icon: 'el-icon-s-order',
                    childrens: [
                        {
                            title: '订单管理',
                            path: '/orderManage',
                            childrens: []
                        },
                        {
                            title: '物流监控',
                            path: '/orderMonitoring',
                            childrens: []
                        },
                    ]
                },
                {
                    title: '商品中心',
                    path: '/commodity',
                    icon: 'el-icon-goods',
                    childrens: [
                        {
                            title: '我的仓库',
                            path: '/myWarehouse',
                            childrens: []
                        },
                        {
                            title: '商品库存',
                            path: '/commodityCenter',
                            childrens: []
                        }
                    ],
                },
                {
                    title: '配置中心',
                    path: '/info',
                    icon: 'el-icon-user',
                    childrens: [
                        {
                            title: '人员配置',
                            path: '/userConfig',
                            childrens: []
                        },
                    ]
                },
            ]
            this.SetPagesRouters(pagesRouters)
        }
    },
    mounted() {
    },
    methods: {
        ...mapMutations(['SetPagesRouters']),
        handleSelect(key, keyPath) {
            // console.log(key, keyPath);
        }
    },
}
</script>

<style lang="scss" scoped>
.aside {
    width: 100%;
    height: 100%;
    background-color: #ffffff;

    .logo {
        width: 100%;
        height: 80px;

        &>img {
            width: 100%;
            display: block;
            margin: 0px auto;
        }
    }

    .el-menu-vertical-demo {
        width: 100%;
        height: calc(100% - 80px);
        border: none;
    }
}
</style>
